/*
.ImageScreen {
    position: relative;
    height: 100%;
    background-color: #00185b;
    
}

.ImageScreen__gradient {
    width: 100%;
    height:fit-content;
    background-color: rgba(166, 166, 166, 0.4);
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.8) 0,
        rgba(0,0,0,0) 60%,
        rgba(0, 0, 0, 0.8) 100%
    );

}

.ImageScreen__body > h1 {
    font-size: 3.125rem;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    white-space: nowrap;
    color: rgb(83, 145, 57);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight:100

}

.ImageScreen__body > h2 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    white-space: nowrap;
    color: rgb(83, 145, 57);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 190%;
    font-weight: 500;
    

}

.ImageScreen__body > p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    white-space: nowrap;
    color: rgb(83, 145, 57);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100%;
    font-weight: 600;
    

}

.ImageScreen__body {
    top: 10%;
    z-index: 1;
    background-color: white;
    color: #fff;
    padding: 10px;
    text-align: center;
    align-items: center;
    margin-left: auto;
    width: 100%;
    left: 0;
    right: 0;

    
}


.ImageScreen__entry > p {
    color:rgba(31, 65, 38, 0.726);
    align-items: center;
    text-align:center;
    padding-right: 4.1%;
   
       
}

.ImageScreen__entry {
    color:rgba(31, 65, 38, 0.726);
    align-items: center;
    text-align:center;
   
       
}

.ImageScreen__entry > title {
    color:rgba(31, 65, 38, 0.726);
    align-items: center;
    text-align:center;
    font-weight: bold;
   
       
}


.ImageScreen__title > h2 {
    color:rgba(31, 65, 38, 0.726);
    color: rgb(83, 145, 57);
    font-weight: 500;
    padding-right: 3.3%;
    

}



.container {
    background-image: url('./lightupPic.jpg');
}

*/

.ImageScreen {
    width: 300px;
    height: 200px;
    border: 1px solid #000;
    margin-left: 27%;
    border-radius: 10%;
}

.ImageScreen > h2 {
    background-color: blue;
}
.ImageScreen > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}