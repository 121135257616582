.signupScreen {
    max-width: 300px;
    padding: 50px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0,0,0,0.85);
}


.signupScreen > form > p {
    font-size: 10.9px;
    color: gray;
}


.signupScreen > form {
    display: grid;
    flex-direction: column;

}

.signupScreen > form > input{
    outline-width: 0;
    height: 40px;
    margin-bottom: 14px;
    border-radius: 5px;
    border:none;
    padding: 5px 15px;
    color: black;

}

.signupScreen > form > button {
    padding: 16px 20px;
    font-size: 1rem;
    color: white;
    border-radius: 5px;
    background-color: rgb(83, 145, 57);
    cursor: pointer;
    border: none;
    margin-top: 20px;
    font-weight: 600;
}

.sigupScreen > form > h1 {
    text-align: left;
    margin-bottom: 25px;

}

.signupScreen > form > h4 {
    text-align: left;
    margin-top: 30px;
}

.signupScreen__gray {
    color: white;
    cursor:default;
    font-weight: 100;

}

.sigupScreen__link:hover {
    cursor: pointer;
    text-decoration: underline;
}


@media(max-width: 600px) {
    .signupScreen {
        max-width: 280px;
        padding: 30px;
        margin-left:auto ;
        margin-right: 2%;
        background-color: rgba(0,0,0,0.85);
    }
    
    .signupScreen > form {
        display: grid;
        flex-direction: column;
    
    }
    
    .signupScreen > form > input{
        outline-width: 0;
        height: 40px;
        margin-bottom: 14px;
        border-radius: 5px;
        border:none;
        padding: 5px 15px;
        color: black;
    
    }
    
    .signupScreen > form > button {
        padding: 16px 20px;
        font-size: 1rem;
        color: white;
        border-radius: 5px;
        background-color: rgb(83, 145, 57);
        cursor: pointer;
        border: none;
        margin-top: 20px;
        font-weight: 600;
    }
    
    .sigupScreen > form > h1 {
        text-align: left;
        margin-bottom: 25px;
    
    }
    
    .signupScreen > form > h4 {
        text-align: left;
        margin-top: 30px;
    }
    
    .signupScreen__gray {
        color: white;
        cursor:default;
        font-weight: 100;
    }
    
    .signupScreen__link:hover {
        cursor: pointer;
        text-decoration: underline;
        

    }



}