.privacyPolicy__body {
    background-color: white;
   
}


.privacyPolicy__body > p {
    color: black;
    font-size: 15px;

}


.privacyPolicy__body > h2 {
    color: black;
    font-weight: 200;

}

.privacyPolicy__body > h1 {
    color: black;
    padding-top: 4%;
    font-weight: 200;
}

.privacyPolicy__body > ul > li {
    color: black;
    font-size: 15px;
}

.privacyPolicy__body > p > a {
    color: black;
    padding-top: 4%;
    font-weight: 200;
}