* {
    color: white;
    font-family: Helvetica;
  
  }
  
  body {
    background-color: midnightblue;
    margin: auto;
    padding: auto;
  
  }
  
  .homeScreen {
    background-color:rgb(0, 15, 46);
    display: flex;
    flex-direction:row-reverse;
    
  }


 
  
  /*
  .side-bar {
    background-color:rgb(42, 41, 43);
    height: 50vh;
    width:  244px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    margin-top: 3%;
  
    
  
  }
  */
  
  .newChat {
    border: solid 0.5px rgb(red, green, blue);
    
    background-color:rgb(7, 7, 37);
    border-radius: 7px;
    padding: 10px;
    padding-right: 3%;
    padding-left: 2%;
    margin: 10px;
    cursor: pointer;
  }
  
  .main {
    height: 100vh;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    
  
  }
  
  /*
  .settings {
    background-color: rgb(83, 145, 57);
    font-size: medium;
    padding: 15px;
    border:none;
    margin-top: 20%;
    border-radius: 40%;
    cursor: pointer;

  }
  */
  /*
  .history {
  
    padding: 10px;
    margin: 10px;
    height: 50%;
  }
  
  .history li {
    list-style-type: none;
    padding: 15px 0;
    cursor: pointer;
    
  }
  */
  
  /*
  .info {
    color:rgb(red, green, blue);
    font-size: 11px;
    padding: 10p;
  }
  */
  
  .bottom-section {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  .input-container {
    position: relative;
    width: 90%;
align-items: center ;
   margin-left: 25%;

  }
  
  .input_text {
    width: 70%;
    border: none;
    outline: none;
  
    background-color: rgb(36, 36, 120);
    padding: 11px 11px;
    padding-right: 3%;
    border-radius: 7px;
    box-shadow: rgba(0,0,0, 0.05) 0 54px 55px,
          rgba(0,0,0, 0.05) 0 -12px 30px,
          rgba(0,0,0, 0.05) 0 4px 6px,
          rgba(0,0,0, 0.05) 0 12px 3px,
          rgba(0,0,0, 0.05) 0 -3px 5px;        
  
   
      white-space:pre-wrap;
    font-size: medium;
    color: #e2f1f9;

     }

  
  
  input:focus {
    outline: none
  }
  
  #submit {
    position: absolute;
    bottom: -1px;
    right: -2px;
   
    margin-right: 8%;
    cursor: pointer;
  }
  
  .submit_btn {
    border: solid 0.5px rgb(red, green, blue);
    
    background-color:rgb(7, 7, 37);
    border-radius: 6px;
    padding-top: 50%;
    padding-left: 80%;
    padding-right: 80%;
    padding-bottom: 50%;
    cursor: pointer;
    margin-left: 50%;
    margin-bottom: 60%;
  
  }
  
  .submit_btn.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  /*
  .feed li {
    display: flex;
    background-color: #444654;
    width: 100%;
    padding: 20px;
    margin: 2px 0;
    border-radius: 4%;
  
  }
  
  
  .feed p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    text-align: left;
    line-height: 170%;
    
  }
  
  .feed p.role {
    min-width: 30px;
  
  }

*/



.feed {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow-y: auto;
  padding: 89px;
  font-weight: lighter;
  line-height: 1.7;

}




  /*
   {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow:wrap;
}

*/

.user-message {
  text-align: left;
  
}

.bot-message {
  text-align: left;
  margin-block-end: 5%;
  margin-left: 10%;
  color: blueviolet;
  
 
}

.message-container {
  background-color: #002043;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: inline-block;
  max-width: 70%;
  
  
}

.role {
  font-weight:100;
  margin-bottom: 5px;
}

.content {
  margin: 0;
  color: #bfcad6;
  
}

.container {
  background-image: url('./lightupPic.jpg');
}

.bottom-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  
  padding: 10px;
  padding-left: 30%;
}


.navbar {
  height: var(--nav-size);
  background-color: var(--background);
  padding: 0 1rem;
  border-bottom: var(--border);
}

.navbar-nav {
 max-width: 100%;
 height: 100%;
 display: flex;
 justify-content: flex-end;
}

.homeScreen_nav-item {
 display: flex;
 align-items: center;
 justify-content: center;

}

.homeScreen_icon-button {
 
 border-radius: 15%;
 padding: 11px;
 margin: 1%;
 display: flex;
 align-items: center;
 justify-content: center;
 transition: filter 300ms;
 cursor: pointer;
 padding-bottom: 21%;

}



.homeScreen_dropdown {
 position: absolute;
 top: 69px;
 width: 180px;
 transform: translate(-40%);
 background-color: #00185b;

 border-radius: 5%;
 
 overflow: hidden;

}

.homeScreen_menu-item {
 height: 35px;
 display: flex;
 align-items: center;
 border-radius: 3%;
 transition: background var(--speed);
 padding: 0.5rem;
 cursor: pointer;
font-size:15px
}

.homeScreen_menu-item:hover {
 background-color: #23242b;
}

.homeScreen__top {
  display: flex;
  align-items:center;
  margin-left: 20%;

}

@media(max-width: 600px) {

  .main {
    height: 100vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding-right: 7%;

  }


  .feed {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 1;
    overflow-y: auto;
    font-weight: lighter;
    line-height: 1.7;
    margin-block-end: 29%;

  }
  
  

  .homeScreen__top {
    display: flex;
    align-items:center;
    margin-left: 25%;
  

    
  }


  .message-container {
    background-color: #002043;
    padding: 7px;
    border-radius: 8px;
    padding-right: 82px;
    padding-bottom: 15px;

  }

  .role {
    font-weight:100;
    margin-bottom: 5px;


  }
  
  .content {
    color: #bfcad6;
    width: 129%;

  }

  .user-message {
    text-align: left;

  }
  
  .bot-message {
    text-align: left;
    margin-left: 0%;
    color: blueviolet;
    margin-left: 5%;


  }
  

  .bottom-section {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }


  .newChat {
    border: solid 0.5px rgb(red, green, blue);
    
    background-color:rgb(7, 7, 37);
    border-radius: 7px;
    padding: 10px;
    padding-right: 3%;
    padding-left: 2%;
    margin: 10px;
    cursor: pointer;
    margin-right: 170px;
    
  }

  .input-container {

    position: relative;
    width: 140%;
    margin-right: 230px;
    
  }

  input:focus {
    outline: none
  }
  
  #submit {
    position: absolute;
    bottom: -1px;
    right: -2px;
   
    margin-right: 8%;
    cursor: pointer;
  }
  

  .submit_btn {
    border: solid 0.5px rgb(0, 0, blue);   
    background-color:rgb(7, 7, 37);
    border-radius: 6px;
    padding-top: 50%;
    padding-left: 80%;
    padding-right: 80%;
    padding-bottom: 50%;
    cursor: pointer;
    margin-left: 50%;
    margin-bottom: 60%;
    touch-action: manipulation;
  
  }
  

}